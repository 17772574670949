import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';

const Tags = ({ children }) =>
  children && (
    <ul style={{ listStyle: `none` }} className="mb-2">
      {children.map((t) => (
        <li className="mr-1" style={{ display: `inline` }} key={t}>
          <Link className="badge badge-info" to={`/blog/tags/${kebabCase(t)}/`}>
            {t}
          </Link>
        </li>
      ))}
    </ul>
  );

export default Tags;
