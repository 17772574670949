import React from 'react';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Tags from '../components/Tags';
import Author from '../components/Author';

const Blog = ({ data, location }) => {
  const { title, author } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { previous, next } = data;

  return (
    <Layout bodyClass="page-services-single">
      <SEO
        title={title}
        description={
          data.markdownRemark.frontmatter.description ||
          data.markdownRemark.excerpt
        }
        image={data.markdownRemark.frontmatter.image}
        url={location.href}
      />
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-10">
            <article className="blog-post">
              <h1 className="title">{title}</h1>

              <div className="d-flex justify-content-between">
                <Tags>{data.markdownRemark.frontmatter.tags}</Tags>
                <a href="/blog/rss.xml" title="RSS Feed">
                  <img width="24" src="/images/social/rss.svg" alt="RSS" />
                </a>
              </div>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: html }}
              />

              <nav>
                <ul className="prevnext">
                  <li>
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        {'← '}
                        {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title}
                        {' →'}
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>

              <br />

              <Author authorId={kebabCase(author)} />
            </article>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $previousPostId: String, $nextPostId: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        tags
        image
        author
      }
      fields {
        slug
      }
      html
      excerpt
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

export default Blog;
